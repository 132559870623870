<template>
  <div class="guide-figure">
    <div class="guide-text">{{ error }}</div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
import { getContext, openEnterpriseChat } from '../../utils/nwechat'
import { checkIsFriend } from '../../service/wechat.service.js'

export default {
  name: 'OpenChart',
  data() {
    return { error: '加载中...', externalUserIds: '', member_userid: '' }
  },
  mounted() {
    // // 成员id
    // console.log(th)
    this.init()
  },
  // watch: {
  //   $route(val, old) {
  //     // console.log(val, old)
  //     alert(old.url)
  //   }
  // },
  methods: {
    // async judgeisFrends() {},
    async init() {
      let userInfo = JSON.parse(localStorage.getItem('vuex'))
      this.member_userid = userInfo.user.info.qw_userid
      this.externalUserIds = this.$route.query.externalUserIds || ''

      const { data } = await checkIsFriend({
        qw_userid: this.member_userid,
        qw_external_userid: this.externalUserIds
      })

      if (data.status) {
        // alert('当前客户好友关系')
        this.$nextTick(() => {
          console.log('this.initEntry()')
          this.initEntry()
        })
      } else {
        // alert('当前客户为非好友关系，请通过其他方式联系客户。')
        Dialog.alert({
          title: '提示',
          message: '当前客户为非好友关系，请通过其他方式联系客户。'
        }).then(() => {
          window.location.href = 'about:blank'
          window.close()
          wx.closeWindow()
        })
      }
    },

    async initEntry() {
      Toast.loading({
        message: '寻路中...',
        duration: 0,
        forbidClick: true
      })
      this.error = '加载中...'
      console.log('#1')
      try {
        await openEnterpriseChat({
          userIds: this.member_userid,
          externalUserIds: this.externalUserIds
        })
        // ios手机不关闭原来的寻路页面
        let isIphone = await this.isFromIphone()
        if (!isIphone) {
          window.location.href = 'about:blank'
          window.close()
          wx.closeWindow()
        }
        // const entry = await getContext()
        // console.log('#2')
        // switch (entry) {
        //   case 'group_chat_tools': // 群聊 - 群看板
        //     const id = await getCurExternalChat()
        //     console.log('chatId:', id)
        //     this.$router.replace({ name: 'community-analyse', query: { id } })
        //     console.log('#3')
        //     break
        //   case 'single_chat_tools': // 单聊 - 客户画像
        //     this.$router.replace({ name: 'customer-portrait-tool' })
        //     console.log('#4')
        //     break
        //   default:
        //     this.error = '请从聊天工具栏进入！'
        // }
      } catch (e) {
        console.log('#5')
        console.log(e)
        // this.error = '请重试！'
        this.error = '加载中...'
      }
      console.log('#6')
      Toast.clear()
    },

    async isFromIphone() {
      let flag = false
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        flag = true
      }
      // this.isIphone = flag
      return flag
    }
  }
}
</script>

<style lang="less" scoped>
.guide-text {
  padding: 24px 36px;
  text-align: center;
  color: #969799;
  font-size: 18px;
}
</style>
